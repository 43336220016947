// @ts-nocheck
/* eslint-disable */

import {useCallback, useEffect, useRef} from 'react';
import {addToClasses, removeFromClasses} from '@modules/Core/util/css';
import {useUiStore, zustandApi} from '../logic/zustand';

/**
 * useApplyBreakpoints - Hook to apply breakpoints to the root (div) elements class. Also enables reactivity to window resize events.
 * we only discriminate between mobile and desktop. The breakpoint is set to 900px.
 */

export const useApplyBreakpoints = () => {
  const isMobile = useUiStore(state => state.isMobile);

  const windowSize = useRef({
    width: undefined,
    height: undefined,
  });

  const currentSize = useRef();

  // add the classname to the root element without causing a re-render
  const handleResize = useCallback(() => {
    // Set window width/height to state
    windowSize.current = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    const scaleFactor = Math.max(640, Math.min(window.innerWidth, 1920)) / 1920;
    document.documentElement.style.setProperty('font-size', `${scaleFactor * 16}px`);
    zustandApi.updateUiStore({scaleFactor});

    // update the window width, height and breakpoint in the DOM (see BreakpointHelper.jsx)
    const widthEl = document.getElementById('window_width');
    const heightEl = document.getElementById('window_height');
    if (widthEl) widthEl.innerHTML = window.innerWidth.toString();
    if (heightEl) heightEl.innerHTML = window.innerHeight.toString();

    let newClass = 'bp-desktop';

    let newIsMobile;

    // set the classname based on the window width
    if (window.innerWidth < 992) {
      newClass = 'bp-mobile';
      newIsMobile = true;
      // TODO @Sherif @Stefan: This is a hack till we build actual responsive design
      // document.documentElement.style.setProperty('--scale-factor', '0.25');
    }
    if (window.innerWidth >= 992) {
      newClass = 'bp-desktop';
      newIsMobile = false;
    }

    // apply only if the class has changed
    if (newClass !== currentSize.current) {
      // console.log('-- breakpoint changed -- ', {newClass}, {isMobile});
      if (newIsMobile !== isMobile) {
        zustandApi.updateUiStore({isMobile: newIsMobile});
      }

      const bpEl = document.getElementById('window_breakpoint');
      if (bpEl) bpEl.innerHTML = newClass.toString();

      removeFromClasses('root', currentSize.current);
      addToClasses('root', newClass);
      currentSize.current = newClass;
    }
  }, [isMobile]);

  useEffect(() => {
    // Handler to call on window resize
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    setTimeout(() => handleResize(), 0);
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]); // Empty array ensures that effect is only run on mount

  return null;
};
