// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef, useState} from 'react';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {Typography} from '@modules/Core/components/base/Typography';
import {setMobileSeen} from '@modules/Core/state/cookiesSlice';
import {isMobileDevice} from '@modules/Core/util/util';
import {useProfile} from '@modules/Profile/hooks/profile';
import {useSelectState} from '@modules/State/hooks/selectState';
import {dispatchAction} from '@modules/State/util/util';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {}

export const MobileBanner: React.FC<_Props> = ({}) => {
  const [currentProfile] = useProfile();
  const [open, setOpen] = useState(false);

  const mobileSeen = useSelectState(state => state.cookies?.mobileSeen);

  const contentRef = useRef(null);

  const isMobile = isMobileDevice();

  useEffect(() => {
    if (isMobile && !mobileSeen && currentProfile) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [mobileSeen, currentProfile]);

  const handleClose = (): void => {
    void dispatchAction(setMobileSeen(true));
  };

  return (
    <Dialog open={open} onClose={handleClose} onConfirm={handleClose} aria-labelledby="mobile-banner" confirmLabel="OK">
      <div ref={contentRef}>
        <Typography dangerouslySetInnerHTML={{__html: trans('base.mobile_banner')}} />
      </div>
    </Dialog>
  );
};

export default MobileBanner;
