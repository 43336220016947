// @ts-nocheck
/* eslint-disable */

import {LOCATION_CHANGED_APP_EVENT} from '@modules/Navigation/hooks/navigationInit';

export interface _TrackingConfigPeriodicalMetadataInput {
  startTime: string;
  endTime: string;
}

export interface _TrackingConfigEventMetadataInput {
  time: string;
  path: string;
  search: Record<string, string>;
  hash: string;
}

export interface _TrackingConfig {
  name: string;
  type: 'periodical' | 'event';
  // Period in seconds, valid only for type = periodical
  period?: number;
  // Event name to listen to, valid only for type = event
  eventName?: string;
  fireOnStart?: boolean;
  eventCallback?: any;

  // Backend tracking event data
  category: string;
  eventType?: string;
  action?: string;
  metadata?: (
    params: _TrackingConfigPeriodicalMetadataInput | _TrackingConfigEventMetadataInput
  ) => Record<string, any>;
  withEvent?: boolean;
  withSave?: boolean;
}

export enum _TrackingCategories {
  userActivity = 'userActivity',
  routeChange = 'routeChange',
}

// TODO: In the future, we can add params to the tracking config, for example, to send additional data to the backend
// Any tracking configuration should be added here, will automatically be started on app init
// Check ../hooks/onInit.ts for the logic
export const runningTrackingsConfigs: _TrackingConfig[] = [
  // Every 5 min send a periodical tracking
  {
    name: 'periodical-5min',
    type: 'periodical',
    period: 60 * 3,
    category: _TrackingCategories.userActivity,
    eventType: _TrackingCategories.userActivity,
    metadata: ({startTime, endTime, pathname, search, hash}) => ({
      startTime,
      endTime,
      pathname,
      search,
      hash,
    }),
    withEvent: true,
    withSave: true,
  },
  {
    name: 'route-change',
    type: 'event',
    category: _TrackingCategories.userActivity,
    eventType: _TrackingCategories.routeChange,
    eventName: LOCATION_CHANGED_APP_EVENT,
    fireOnStart: true,
    metadata: ({time, pathname, search, hash}) => ({
      time,
      path: pathname,
      params: search,
      hash,
    }),
    withEvent: true,
    withSave: true,
  },
];

export const activityTimeoutInSeconds = 60 * 5;
