// @ts-nocheck
/* eslint-disable */

import moment from 'moment/moment';
import {_Notification} from '@modules/Notifications/util/notification.model';

const minutesInHour = 60;
const minutesInDay = 60 * 24;
const minutesInWeek = 60 * 24 * 7;
const minutesInMonth = 60 * 24 * 30;
const minutesInYear = 60 * 24 * 365;

const minuteKey = 'minute';
const hourKey = 'hour';
const dayKey = 'day';
const monthKey = 'month';
const weekKey = 'week';
const yearKey = 'year';

export function mapNotification(notification: Record<string, any>): _Notification | null {
  if (!notification) {
    return null;
  }
  return {
    id: notification?.id,
    read_at: notification.read_at,
    read: !!notification.read_at,
    message: notification?.data?.message,
    link: notification?.data?.link,
    params: notification?.data,
    type: notification?.data?.type,
    title: notification?.data?.title,
    createdAt: notification?.created_at,
    ...createNotificationTimeString(notification?.created_at as string),
  };
}

function createNotificationTimeString(createdAt: string): {
  timePassed: number | null;
  timePassedLangProperty: string | null;
} | null {
  if (!createdAt) {
    return null;
  }

  const date = new Date(createdAt);

  // create moment object from time
  const timeMoment = moment(date, 'YYYY-MM-DD HH:mm:ss');
  // get current time
  const currentTime = moment();
  // get difference between current time and timeMoment
  const diff = currentTime.diff(timeMoment, 'minutes').valueOf();

  // If diff < 60, show "just now"
  // If diff < 60 * 24, show "x hours ago"
  // If diff < 60 * 24 * 7, show "x days ago"
  // If diff < 60 * 24 * 7 * 4, show "x weeks ago"
  // If diff > 60 * 24 * 7 * 4, show "x months ago"
  // If diff > 60 * 24 * 7 * 4 * 12, show "x years ago"

  const result: {timePassed: number | null; timePassedLangProperty: string | null} = {
    timePassed: null,
    timePassedLangProperty: null,
  };

  if (diff < 1) {
    result.timePassed = 0;
    result.timePassedLangProperty = 'just_now';
  } else if (diff < minutesInHour) {
    result.timePassed = diff;
    result.timePassedLangProperty = minuteKey;
  } else if (diff < minutesInDay) {
    result.timePassed = Math.floor(diff / minutesInHour);
    result.timePassedLangProperty = hourKey;
  } else if (diff < minutesInWeek) {
    result.timePassed = Math.floor(diff / minutesInDay);
    result.timePassedLangProperty = dayKey;
  } else if (diff < minutesInMonth) {
    result.timePassed = Math.floor(diff / minutesInWeek);
    result.timePassedLangProperty = weekKey;
  } else if (diff < minutesInYear) {
    result.timePassed = Math.floor(diff / minutesInMonth);
    result.timePassedLangProperty = monthKey;
  } else {
    result.timePassed = Math.floor(diff / minutesInYear);
    result.timePassedLangProperty = yearKey;
  }

  return result;
}
