// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {getRouteIcon} from '@/_core/util/routesHelper';
import {IconExpandLess, IconExpandMore} from '@modules/Core/components/base/Icons/Icons';
import {ListItem} from '@modules/Core/components/base/inputs/ListItem';
import {_NestedRoute, _NewSideBarRoute, _Route} from '@modules/Core/types/routesTypes';
import {kebabize} from '@modules/Core/util/strings';
import {fireDialogFlow} from '@modules/FlowEngine/util/dialogFlowsUtil';
import {useAppNavigate} from '@modules/Navigation/hooks/appNavigate';
import {trans} from '@modules/Translations/util/i18n';
import {getSidebarItemId} from '@modules/Tutorials/util/tutorialIdUtil';

interface _SideBarContent {
  data: _NewSideBarRoute;
}

function isNestedRoute(data: _Route | _NestedRoute): data is _NestedRoute {
  return 'collapsable' in data && 'items' in data;
}

export const SideBarEntries: React.FC<_SideBarContent> = ({data}) => {
  const {items} = data ?? {};

  return items?.map((item, i) =>
    isNestedRoute(item) ? (
      <SideBarEntryCollapsable key={item.value} data={item} />
    ) : (
      <SideBarEntry key={item.value} data={item} />
    )
  );
};

interface _SideBarEntryCollapsable {
  data: _NestedRoute;
}

const SideBarEntryCollapsable: React.FC<_SideBarEntryCollapsable> = ({data}) => {
  const {value, items, displayValue, active} = data;
  const [isExpanded, setIsExpanded] = useState(active);

  const icon = getRouteIcon(value);

  const collapsibleIcon = !isExpanded ? IconExpandMore : IconExpandLess;

  const handleClickExpand = (): void => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="flex items-start flex-col w-full">
      <ListItem label={displayValue} icon={icon} endIcon={collapsibleIcon} onChange={handleClickExpand} lineClamp />

      <div className="pl-1 w-full">
        {isExpanded ? items.map((item, i) => <SideBarEntry nested key={item.value} data={item} />) : null}
      </div>
    </div>
  );
};

interface _SideBarEntryProps {
  data: _Route;
  nested?: boolean;
}

const SideBarEntry: React.FC<_SideBarEntryProps> = ({data, nested}) => {
  const {navigate} = useAppNavigate();
  const {to, value, displayValue} = data;
  const icon = getRouteIcon(value);
  const isActiveRoute = data.active;

  const handleClick = (): void => {
    if (data.asPopup) {
      fireDialogFlow(data.flowKey!, null, {
        hideButtons: true,
        showCloseButton: true,
      });
      return;
    }
    if (!to) return;
    navigate(to);
  };

  if (!data.flowKey) {
    return null;
  }

  return (
    <div className="w-full" id={getSidebarItemId(kebabize(data.flowKey))}>
      <ListItem
        active={isActiveRoute}
        label={trans(displayValue as string)}
        icon={icon}
        nested={nested}
        onChange={handleClick}
        lineClamp
      />
    </div>
  );
};
