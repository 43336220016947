// @ts-nocheck
/* eslint-disable */

import {createSlice} from '@reduxjs/toolkit';
import {Permission} from '../types/permission.model';
import {Role} from '../types/role.model';
import {logger} from '../util/Logger';

export interface _RolesAndPermissionsState {
  roles: Role[];
  permissions: Permission[];
  resources: Record<string, string[]>;
}

const rolesAndPermissionsSlice = createSlice({
  name: 'rolesAndPermissions',
  initialState: {
    roles: [],
    permissions: [],
    resources: {},
  } satisfies _RolesAndPermissionsState,
  reducers: {
    setResources(state: _RolesAndPermissionsState, action) {
      logger.info('[RolesAndPermissionsSlice] Updating resources.');

      state.resources = action.payload;
    },
    resetRolesAndPermissions(state: _RolesAndPermissionsState) {
      logger.info('[RolesAndPermissionsSlice] Resetting roles and permissions.');

      state.roles = [];
      state.permissions = [];
    },
    setRolesAndPermissions(state: _RolesAndPermissionsState, action) {
      logger.info('[RolesAndPermissionsSlice] Updating roles and permissions.');

      state.roles = action.payload;
    },
  },
});

export const {resetRolesAndPermissions, setRolesAndPermissions, setResources} = rolesAndPermissionsSlice.actions;
export default rolesAndPermissionsSlice;
