// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconAdd, IconCancel} from '@modules/Core/components/base/Icons/Icons';
import {Action} from '@modules/Core/components/layout/Action';
import {_UIAction} from '@modules/Core/types/pageLayout.model';
import './Fab.css';
import {useClickOutOfBoundsHelper} from '@/_core/util/cssHelper';
import useOnMouseEnter from '@modules/Core/hooks/onMouseEnter';
import {getTutorialTriggerId} from '@modules/Tutorials/util/tutorialIdUtil';

interface _Props {
  actions?: _UIAction[];
}

const id = 'app-fab';
const triggerDistancePx = 65;
export const Fab: React.FC<_Props> = ({actions}) => {
  const [isActionsVisible, setIsActionsVisible] = useState(false);
  const handleClickOutside = (): void => {
    setIsActionsVisible(false);
  };
  const ref = useClickOutOfBoundsHelper(handleClickOutside, id);

  useOnMouseEnter({
    ref,
    distance: triggerDistancePx,
    onMouseEnter: () => setIsActionsVisible(true),
    onMouseLeave: () => setIsActionsVisible(false),
  });

  if (!actions?.length) {
    return null;
  }

  return (
    <div ref={ref} id={id} className="fixed bottom-8 right-10 flex flex-col items-end z-[1001]">
      {/* Render additional action buttons when isActionsVisible is true */}
      <div className="relative h-full w-full min-h-full min-w-full">
        {actions?.map((action, index) => {
          const radius = 60; // Adjust as needed to control spacing
          const angle = index * (Math.PI / 4); // 45 degrees in radians
          const x = Math.cos(angle) * radius;
          const y = Math.sin(angle) * radius;

          return (
            <div
              key={index}
              // className="absolute transition-transform duration-300 ease-out fab-action"
              className={`absolute fab-trigger fab-action ${isActionsVisible ? 'fab-action--visible' : ''}`}
              style={{
                '--x': `${-x}px`,
                '--y': `${-y}px`,
              }}
            >
              <Action fab {...action} />
            </div>
          );
        })}
        {/* Main FAB button */}
        <div
          className=" bg-blue-600 hover:bg-blue-700 text-white rounded-full shadow-lg fab-trigger"
          style={{
            transform: 'translate(4px, 4px)',
          }}
        >
          <IconButton
            id={getTutorialTriggerId(id)}
            size="lg"
            radius="lg"
            icon={isActionsVisible ? IconCancel : IconAdd}
            active={isActionsVisible}
          />
        </div>
      </div>
    </div>
  );
};
