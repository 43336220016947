// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {useGesture} from '@use-gesture/react';
import {generate} from '@modules/Core/components/base/helper';
import {IconExpandMore, IconShare} from '@modules/Core/components/base/Icons/Icons';
import {CheckBox} from '@modules/Core/components/base/inputs/CheckBox';
import {useUiStore, zustandApi} from '@modules/Core/util/logic/zustand';
import {isDevMode} from '@modules/Core/util/util';

export const DevMenu: React.FC = () => {
  const devMenuExpanded = useUiStore(state => state.devMenuExpanded);
  // const showDevMenu = useUiStore(state => state.showDevMenu);
  const showDevMenu = isDevMode();

  if (!showDevMenu) return null;

  return devMenuExpanded ? <DevMenuExpanded /> : <DevMenuClosed />;
};

const DevMenuExpanded: React.FC = () => {
  const showBreakpointHelper = useUiStore(state => state.showBreakpointHelper);
  const showSideBar = useUiStore(state => state.showSideBar);
  const showTopBar = useUiStore(state => state.showTopBar);
  const showGridHelper = useUiStore(state => state.showGridHelper);
  const showDevOutput = useUiStore(state => state.showDevOutput);
  const showDevComponents = useUiStore(state => state.showDevComponents);

  const buttons = [
    {
      action: () => zustandApi.updateUiStore({showBreakpointHelper: !showBreakpointHelper}),
      active: showBreakpointHelper,
      label: 'Show breakpoint helper',
    },
    {
      action: () => zustandApi.updateUiStore({showTopBar: !showTopBar}),
      active: showTopBar,
      label: 'Show top bar',
    },
    {
      action: () => zustandApi.updateUiStore({showSideBar: !showSideBar}),
      active: showSideBar,
      label: 'Show side bar',
    },
    {
      action: () => zustandApi.updateUiStore({showGridHelper: !showGridHelper}),
      active: showGridHelper,
      label: 'Show grid helper',
    },
    {
      action: () => zustandApi.updateUiStore({showDevComponents: !showDevComponents}),
      active: showDevComponents,
      label: 'Show Dev Components',
    },
    {
      action: () => zustandApi.updateUiStore({showDevOutput: !showDevOutput}),
      active: showDevOutput,
      label: 'Show Dev Output',
    },
    {
      action: () => zustandApi.addToGlobals('globalAlerts', {label: 'success man', id: generate(), color: 'success'}),
      label: 'Add success alert',
    },
    {
      action: () => zustandApi.addToGlobals('globalAlerts', {label: 'warning', id: generate(), color: 'warning'}),
      label: 'Add warning alert',
    },
    {
      action: () => zustandApi.addToGlobals('globalAlerts', {label: 'danger', id: generate(), color: 'danger'}),
      label: 'Add danger alert',
    },
  ];

  const initX = 12;
  const initY = 12;

  const [position, setPosition] = useState({x: initX, y: initY});

  const bind = useGesture({
    onDrag: state => {
      setPosition({x: -state.offset[0] + initX, y: state.offset[1] + initY});
    },
  });

  return (
    <div
      {...bind()}
      style={{
        right: position.x,
        top: position.y,
        position: 'absolute',
        zIndex: 1000,
        touchAction: 'none',
      }}
    >
      <div className="rounded-sm bg-[#2D382A] overflow-hidden">
        <div className="flex w-full h-[34px] items-center justify-end px-2">
          <button
            type="button"
            className="bg-[#696969] rounded-xs flexCenterCenter w-[20px] h-[20px]"
            onClick={() => zustandApi.updateUiStore({devMenuExpanded: false})}
          >
            <IconExpandMore size="sm" color="fill-white700" />
          </button>
        </div>

        {/* content */}
        <div className="flex flex-col items-start gap-1 b bg-[#191C20] p-2 rounded-t-sm">
          {buttons.map(btn => (
            <button
              type="button"
              onClick={() => btn.action()}
              key={btn.label}
              className="flex flex-row gap-1 items-center"
            >
              <CheckBox active={btn.active} onChange={() => btn.action()} size="sm" color="light" />
              <span className="text-white500 text-caption select-none">{btn.label}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const DevMenuClosed: React.FC = () => (
  <button
    type="button"
    onClick={() => zustandApi.updateUiStore({devMenuExpanded: true})}
    className="fixed z-50 top-[10px] right-[10px] bg-black500 rounded-sm p-1 flex gap-1"
  >
    <IconShare size="sm" color="fill-white700" />
  </button>
);
