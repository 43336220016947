// @ts-nocheck
/* eslint-disable */

import {useEffect} from 'react';
import {sessionConfig} from '@app/_core/util/util';
import {DEFAULT_LANGUAGE} from '@modules/Translations/config/constants';
import {useLanguage} from '@modules/Translations/hooks/language';

export const useOnInitTranslations = (): void => {
  useInitLanguage();
};

const useInitLanguage = (): void => {
  const {setLanguage} = useLanguage();

  useEffect(() => {
    setLanguage(sessionConfig('language') ?? DEFAULT_LANGUAGE);
  }, []);
};
