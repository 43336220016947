// @ts-nocheck
/* eslint-disable */

import {useDidUpdateEffect} from '@modules/Core/hooks/didUpdateEffect';
import {resetNotifications, setNotifications} from '@modules/Core/state/notificationsSlice';
import {logger} from '@modules/Core/util/Logger';
import {_Profile} from '@modules/Profile/types/profile.model';
import {mapNotification} from '@modules/Profile/util/notificationsUtil';
import {useSelectState} from '@modules/State/hooks/selectState';
import {dispatchAction} from '@modules/State/util/util';

export function useInitNotifications(): void {
  const profileState = useSelectState(state => state.profile?.currentProfile) as _Profile | null;

  useDidUpdateEffect(() => {
    logger.info('[InitApp] Profile changed, updating notifications.');
    // If no profile, reset theme
    if (!profileState) {
      dispatchAction(resetNotifications({}));
      return;
    }

    dispatchAction(
      setNotifications((profileState?.notifications || []).map(notification => mapNotification(notification)))
    );
  }, [profileState]);
}
