// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef} from 'react';
import {ContentEngineContainer} from '@modules/ContentEngine/components/base/ContentEngineContainer';
import {addReactEventListener, reloadContentEngine, removeReactEventListener} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {isDevMode} from '@modules/Core/util/util';
import {
  CONTENT_RELOADED_APP_EVENT,
  LOCATION_CHANGED_APP_EVENT,
  PROFILE_RELOADED_APP_EVENT,
} from '@modules/Navigation/hooks/navigationInit';

const key = 'sidebarPrompts';
export const AppSidebarFooter: React.FC = () => {
  const showDevMenu = isDevMode();
  const isLoading = useRef<boolean>(false);
  useEffect(() => {
    const onRouteChange = (): void => {
      if (isLoading.current) {
        return;
      }
      // will reload the sidebar prompts
      setTimeout(() => {
        logger.info('[AppSidebarFooter] Reloading sidebar prompts');
        reloadContentEngine(key);
        isLoading.current = true;
      }, 1000);
    };

    const onContentReloaded = (): void => {
      isLoading.current = false;
    };

    addReactEventListener(CONTENT_RELOADED_APP_EVENT, onContentReloaded);
    addReactEventListener(PROFILE_RELOADED_APP_EVENT, onRouteChange);
    addReactEventListener(LOCATION_CHANGED_APP_EVENT, onRouteChange);

    return () => {
      removeReactEventListener(LOCATION_CHANGED_APP_EVENT, onRouteChange);
      removeReactEventListener(PROFILE_RELOADED_APP_EVENT, onRouteChange);
      removeReactEventListener(CONTENT_RELOADED_APP_EVENT, onContentReloaded);
    };
  }, []);

  logger.debug('[AppSidebarFooter] Rendering', {showDevMenu});
  return (
    <>
      {showDevMenu && <ContentEngineContainer reloadKey={key} type="sidebarPromptsDev" withLoadingIndicator={false} />}
      <ContentEngineContainer reloadKey={key} type="sidebarPrompts" withLoadingIndicator={false} />
    </>
  );
};
