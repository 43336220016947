// @ts-nocheck
/* eslint-disable */

import {useEffectAsync} from '@modules/Core/hooks/effectAsync';
import {loadResources} from '@modules/Core/services/api/rolesAndPermissions';
import {useSelectState} from '@modules/State/hooks/selectState';
import {dispatchAction} from '@modules/State/util/util';
import {resetRolesAndPermissions, setResources, setRolesAndPermissions} from '../../state/rolesAndPermissionsSlice';
import {logger} from '../../util/Logger';
import {useDidUpdateEffect} from '../didUpdateEffect';

export const useInitRolesAndPermissions = (): void => {
  const profileState = useSelectState(state => state.profile?.currentProfile);

  // Load resources
  useEffectAsync(async () => {
    const resourcesResponse = await loadResources();
    dispatchAction(setResources(resourcesResponse.data));
  }, []);
  useDidUpdateEffect(() => {
    logger.info('[InitApp] Profile changed, reloading permissions.');

    if (!profileState) {
      dispatchAction(resetRolesAndPermissions());
      return;
    }

    dispatchAction(setRolesAndPermissions(profileState?.permissions));
  }, [profileState]);
};
