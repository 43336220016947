// @ts-nocheck
/* eslint-disable */

import {FC} from 'react';
import {_Dialog, Dialog} from '@modules/Core/components/base/Dialog';
import {useUiStore} from '@modules/Core/util/logic/zustand';

// maybe not needed anymore
export interface _GlobalDialog extends _Dialog {}

export const GlobalDialog: FC = () => {
  const globalDialog = useUiStore(state => state.globalDialog);

  if (!globalDialog) return null;

  return (
    <div id="globalDialog" className="fixed top-[0px] left-[0px] z-[1000] w-full h-full">
      <div className="relative w-full h-full">
        <Dialog {...globalDialog} containerGap={3} />
      </div>
    </div>
  );
};
