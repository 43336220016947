// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {TextLink} from '@modules/Core/components/base/TextLink';
import {Typography} from '@modules/Core/components/base/Typography';
import {getYear} from '@modules/Core/util/dates';
import {useLanguage} from '@modules/Translations/hooks/language';
import {trans} from '@modules/Translations/util/i18n';

interface _FooterItem {
  key: string;
  label: string;
  href: string;
}

export const Footer: React.FC = () => {
  const {currentLanguage} = useLanguage();
  const [links, setLinks] = useState<_FooterItem[]>([]);

  useEffect(() => {
    setLinks([
      {
        key: 'tos',
        label: trans('register.tos_form_tos_link'),
        href:
          currentLanguage === 'en' ? 'https://softfact.works/terms-and-conditions/' : 'https://de.softfact.works/agb/',
      },
      {
        key: 'privacy',
        label: trans('footer.privacy'),
        href:
          currentLanguage === 'en'
            ? 'https://softfact.works/privacy-policy/'
            : 'https://de.softfact.works/datenschutz/',
      },
      {
        key: 'imprint',
        label: trans('footer.imprint'),
        href: currentLanguage === 'en' ? 'https://softfact.works/imprint/' : 'https://de.softfact.works/impressum/',
      },
    ]);
  }, [currentLanguage]);

  return (
    <div className="flex flex-row justify-between">
      <Typography variant="body">{trans('base.softfact_footer', {year: getYear()})}</Typography>
      <div className="flex flex-row justify-end items-center gap-2">
        {links.map(link => (
          <TextLink color="default" href={link.href} target="_blank" label={link.label} key={link.key} />
        ))}
      </div>
    </div>
  );
};
