// @ts-nocheck
/* eslint-disable */

import {createSlice} from '@reduxjs/toolkit';
import {logger} from '@modules/Core/util/Logger';
import {_FlowSchema} from '../types/core.model';

export interface _FlowsJsonState {
  flows: Record<string, _FlowSchema>;
}

const flowJsonSlice = createSlice({
  name: 'flowJson',
  initialState: {
    flows: {},
  } satisfies _FlowsJsonState,
  reducers: {
    setFlows(state: _FlowsJsonState, action) {
      logger.info('[flowsJsonSlice] Setting flows JSON...');

      state.flows = action.payload;
    },
  },
});

export const {setFlows} = flowJsonSlice.actions;
export default flowJsonSlice;
