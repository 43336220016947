// @ts-nocheck
/* eslint-disable */

import {useEffect} from 'react';
import {slk} from 'survey-core';

export const useInitSurveyJS = () => {
  useEffect(() => {
    slk('ZWM2OWMxNGItMDdmMS00MTNkLWE1NjAtZGVjYzNmYjE2NzQwOzE9MjAyNS0wNy0yNSwyPTIwMjUtMDctMjUsND0yMDI1LTA3LTI1');
  }, []);
};
