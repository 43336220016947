// @ts-nocheck
/* eslint-disable */

import {useEffect} from 'react';
import * as am5 from '@amcharts/amcharts5';

export const useInitAmCharts5 = () => {
  useEffect(() => {
    am5.addLicense('AM5C390452210');
  }, []);
};
