// @ts-nocheck
/* eslint-disable */

import React, {ReactElement} from 'react';
import {Group} from '@modules/Core/components/layout/Group';

export interface _LayoutContentProps {
  children: ReactElement;
  backgroundIcons: ReactElement;
  devFooter: ReactElement;
  footer: ReactElement;
  header?: ReactElement;
}

export const DefaultLayout: React.FC<_LayoutContentProps> = ({
  children,
  backgroundIcons,
  devFooter,
  footer,
  header,
}) => {
  return (
    <div className="flex flex-col justify-between pt-3 pb-5 h-full gap-6 page-layout">
      <div className="flex flex-grow flex-col items-start gap-3">
        {header}
        <div className="flex pt-5 pb-5 rounded-sm bg-white500 shadow-3 page-layout-card">
          {backgroundIcons}
          <div className="relative-col-width-10 page-layout-content">
            <Group>{children}</Group>
          </div>
        </div>
      </div>
      {devFooter}
      {footer}
    </div>
  );
};
