// @ts-nocheck
/* eslint-disable */

import {createSlice} from '@reduxjs/toolkit';
import {logger} from '../util/Logger';

export interface _CookiesState {
  cookiesAccepted: boolean | null;
  mobileSeen: boolean;
}

const cookiesSlice = createSlice({
  name: 'cookie',
  initialState: {
    cookiesAccepted: null,
    mobileSeen: false,
  } as _CookiesState,
  reducers: {
    setCookiesAccepted(state: _CookiesState, action) {
      logger.info('[CookieSlice] Changing cookiesAccepted to ', `${action.payload}.`);
      state.cookiesAccepted = action.payload;
    },
    setMobileSeen(state: _CookiesState, action) {
      logger.info('[CookieSlice] Changing mobileSeen to ', `${action.payload}.`);
      state.mobileSeen = action.payload;
    },
  },
});

export const {setCookiesAccepted, setMobileSeen} = cookiesSlice.actions;
export default cookiesSlice;
