// @ts-nocheck
/* eslint-disable */

import {useEffect} from 'react';
import {sessionConfig, setSessionConfig} from '@app/_core/util/util';
import {useInitAmCharts5} from '@modules/Core/hooks/init/initAmCharts5';
import {useInitRolesAndPermissions} from '@modules/Core/hooks/init/initRolesAndPermissions';
import {useInitSurveyJS} from '@modules/Core/hooks/init/initSurveyJS';
import {useInitTabTitle} from './initTabTitle';

export const useOnInitApp = (): void => {
  useInitTabTitle();
  useInitRolesAndPermissions();
  useInitAmCharts5();
  useInitSurveyJS();

  // Remember initial referrer for tracking
  useEffect(() => {
    if (!sessionConfig('initialReferrer')) {
      setSessionConfig('initialReferrer', document.referrer || window.location.href);
    }
  }, []);
};
