// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {useLoader} from '@modules/Core/hooks/state/loader';
import {useUiStore} from '@modules/Core/util/logic/zustand';

export const DevOutput: React.FC = () => {
  const showDevMenu = useUiStore(state => state.showDevOutput);
  const {loading, resetLoading} = useLoader();

  if (!showDevMenu) return null;

  return (
    <div
      style={{
        position: 'fixed',
        left: '5px',
        bottom: '50px',
        zIndex: 9999,
        overflow: 'auto',
        width: '290px',
        height: '200px',
      }}
    >
      <div className="bg-grey700 p-2 mb-2">
        <pre className="w-full">{JSON.stringify({loading}, null, 2)}</pre>
        <Button variant="brand" onClick={() => resetLoading()}>
          Reset Loader
        </Button>
      </div>
    </div>
  );
};
