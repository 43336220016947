// @ts-nocheck
/* eslint-disable */

import {useCallback, useEffect, useRef, useState} from 'react';

// Opted for custom implementation rather than introducing a new dependency
const useUserActivity = (idleTimeoutInSeconds: number): boolean => {
  const [isIdle, setIsIdle] = useState(false);
  const idleTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const resetIdleTimer = useCallback(() => {
    if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current);
    setIsIdle(false);
    idleTimeoutRef.current = setTimeout(() => setIsIdle(true), idleTimeoutInSeconds * 1000);
  }, [idleTimeoutInSeconds]);

  useEffect(() => {
    const handleActivity = () => resetIdleTimer();

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('mousedown', handleActivity);
    window.addEventListener('scroll', handleActivity);

    resetIdleTimer(); // Initialize the timer

    return () => {
      if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('mousedown', handleActivity);
      window.removeEventListener('scroll', handleActivity);
    };
  }, [resetIdleTimer]);

  return isIdle;
};

export default useUserActivity;
