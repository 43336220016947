// @ts-nocheck
/* eslint-disable */

import {performFlowAction} from '@modules/FlowEngine/services/api';

export const submitProfileTrackingEventAction = async ({
  category,
  source,
  type,
  action = null,
  metadata = {},
  withSave = true,
  withEvent = true,
}) => {
  const method = 'profile.tracking.submit';
  const params = {
    category,
    source,
    type,
    withSave,
    action,
    metadata,
    withEvent,
  };

  try {
    const response = await performFlowAction({
      method,
      params,
    });

    const responseData = response?.data;

    if (responseData?.status === 'success') {
      return responseData;
    }
    return response?.message;
  } catch (e) {
    return null;
  }
};
