// @ts-nocheck
/* eslint-disable */

import {createSlice} from '@reduxjs/toolkit';
import {_Notification} from '../../Notifications/util/notification.model';
import {logger} from '../util/Logger';

export interface _NotificationsState {
  currentNotifications: _Notification[];
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {currentNotifications: []} satisfies _NotificationsState,
  reducers: {
    setNotifications(state: _NotificationsState, action) {
      logger.info('[NotificationsSlice] Updating notifications.');

      state.currentNotifications = action.payload;
    },
    resetNotifications(state: _NotificationsState, action) {
      logger.info('[NotificationsSlice] Resetting notifications');

      state.currentNotifications = [];
    },
  },
});

export const {setNotifications, resetNotifications} = notificationsSlice.actions;
export default notificationsSlice;
