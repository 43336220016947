// @ts-nocheck
/* eslint-disable */

import {useEffect} from 'react';
import {useAuthToken} from '@modules/Core/hooks/state/authToken';
import {reloadProfileState} from '@modules/Core/util/eventsUtil';
import {useInitNotifications} from '@modules/Profile/hooks/initNotifications';
import {useProfileLocationEffects} from '@modules/Profile/hooks/locationEffect';

export function useOnInitProfile(): void {
  const {authToken} = useAuthToken();
  useEffect(() => {
    if (authToken) {
      reloadProfileState();
    }
  }, [authToken]);
  useInitNotifications();
  useProfileLocationEffects();
}
