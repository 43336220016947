// @ts-nocheck
/* eslint-disable */

import React, {useEffect} from 'react';
import {AppSidebarFooter} from '@/_core/sideBar/AppSidebarFooter';
import {SideBarEntries} from '@/_core/sideBar/SideBarEntries';
import {SideBarOrganization} from '@/_core/sideBar/SideBarOrganization';
import {SideBarUser} from '@/_core/sideBar/SideBarUser';
import {usePrepareSideBar} from '@/_core/usePrepareSideBar';
import {Logo} from '@modules/Core/components/base/Logo';
import {_NestedRoute} from '@modules/Core/types/routesTypes';
import {useFlowJsons} from '@modules/FlowEngine/hooks/flowJsons';
import {useInstitution} from '@modules/Institution/hooks/Institution';
import {useProfile} from '@modules/Profile/hooks/profile';
import {useTeams} from '@modules/Team/hooks/teams';
import {SideBarTeams} from './SideBarTeams';

interface _AppSidebarProps {
  showSideBar: boolean;
  setShowSideBar: (show: boolean) => void;
  showLogo?: boolean;
}

export const AppSidebar: React.FC<_AppSidebarProps> = ({showSideBar, setShowSideBar}) => {
  const availableFlows = useFlowJsons();
  const [currentProfile] = useProfile();
  const {currentInstitution} = useInstitution();
  const [currentTeams] = useTeams();
  const sideBarRoutes = usePrepareSideBar(availableFlows, currentProfile, currentInstitution, currentTeams);

  useEffect(() => {
    if (!sideBarRoutes || !currentProfile) {
      setShowSideBar(false);
      return;
    }

    setShowSideBar(true);
  }, [currentProfile, sideBarRoutes]);

  if (!showSideBar) {
    return null;
  }

  return (
    <div className="sideBar fixed h-[100vh] bg-white500 shrink-0 top-[0px] px-1  min-h-0 z-20">
      <div className="relative w-full h-full flex flex-col items-start gap-3 overflow-y-auto overflow-x-hidden">
        <div
          className="h-8 flex items-center justify-start pl-2 shrink-0 sticky bg-white500 w-full z-[1000]"
          style={{top: 0}}
        >
          <Logo href="/" />
        </div>

        {sideBarRoutes.profile?.items && sideBarRoutes.profile.items.length > 0 && (
          <SideBarUser data={sideBarRoutes.profile} userProfile={currentProfile} />
        )}

        {/* <div className="px-1 shrink-0 h-[1px] w-full" id="tutorial-test">
          <Divider />
        </div> */}

        {sideBarRoutes.team?.items && sideBarRoutes.team.items.length > 0 && <SideBarTeams data={sideBarRoutes.team} />}

        {/* <div className="px-1 shrink-0 h-[1px] w-full">
          <Divider />
        </div> */}

        {sideBarRoutes.organisation?.items?.filter(item => (item as _NestedRoute).items?.length > 0).length > 0 && (
          <SideBarOrganization data={sideBarRoutes.organisation} />
        )}

        {/* <div className="px-1 shrink-0 h-[1px] w-full">
          <Divider />
        </div> */}

        {sideBarRoutes.general?.items && sideBarRoutes.general.items.length > 0 && (
          <SideBarEntries data={sideBarRoutes.general} />
        )}

        <AppSidebarFooter />
      </div>
    </div>
  );
};
