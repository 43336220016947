// @ts-nocheck
/* eslint-disable */

import {useEffect} from 'react';
import {useNotifications} from '../../../Notifications/hooks/notifications';
import {APP_TITLE} from '../../config/constants';
import {logger} from '../../util/Logger';
import {useAuthToken} from '../state/authToken';

export const useInitTabTitle = (): void => {
  const {unreadNotifications} = useNotifications();
  const {authToken} = useAuthToken();

  useEffect(() => {
    if (!authToken || !unreadNotifications?.length) {
      document.title = APP_TITLE;
      return;
    }
    logger.info('[InitApp] Unread notifications changed, updating tab title.', {unreadNotifications});
    if (unreadNotifications.length > 0) {
      document.title = `(${unreadNotifications.length || ''}) ${APP_TITLE}`;
      return;
    }

    document.title = APP_TITLE;
  }, [unreadNotifications, authToken]);
};
