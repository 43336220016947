// @ts-nocheck
/* eslint-disable */

import {useEffect, useRef, useState} from 'react';
import {logger} from '@modules/Core/util/Logger';
import {useProfile} from '@modules/Profile/hooks/profile';
import {runningTrackingsConfigs} from '@modules/Tracking/config/trackingsConfig';
import {useTracking} from '@modules/Tracking/hooks/tracking';

export function useOnInitTracking(): void {
  const {eventTrack, periodicalTrack, stopTracking} = useTracking();
  const [currentProfile] = useProfile();
  const trackingStartedForProfileId = useRef<string | null>(null);
  const [runningTrackingIds, setRunningTrackingIds] = useState<string[]>([]);
  useEffect(() => {
    logger.info('[useOnInitTracking] Initializing tracking, profile:', {
      currentProfile,
      trackingStartedForProfileId: trackingStartedForProfileId.current,
    });
    if (!currentProfile) {
      logger.info('[useOnInitTracking] No profile found, stopping tracking');
      return;
    }

    if (trackingStartedForProfileId.current === currentProfile.id) {
      logger.info('[useOnInitTracking] Tracking already started for profile, skipping');
      return;
    }

    startTrackingsByConfig();
    trackingStartedForProfileId.current = currentProfile.id;
  }, [currentProfile]);

  useEffect(() => {
    return () => {
      runningTrackingIds?.forEach(trackingId => {
        logger.info(`[useOnInitTracking] Stopping tracking ${trackingId}`);
        stopTracking(trackingId);
      });

      trackingStartedForProfileId.current = null;
      setRunningTrackingIds([]);
    };
  }, []);

  const startTrackingsByConfig = (): void => {
    runningTrackingsConfigs.forEach(trackingConfig => {
      let trackingId: string | null = null;
      if (trackingConfig.type === 'periodical') {
        if (!trackingConfig.period) {
          logger.error('[useOnInitTracking] Periodical tracking config missing period');
          return;
        }
        logger.info(`[useOnInitTracking] Starting periodical tracking for ${trackingConfig.period} seconds`);
        trackingId = periodicalTrack(trackingConfig);
      } else {
        if (!trackingConfig.eventName) {
          logger.error('[useOnInitTracking] Event tracking config missing eventName');
          return;
        }

        logger.info(`[useOnInitTracking] Starting event tracking for ${trackingConfig.eventName}`);
        trackingId = eventTrack(trackingConfig);
      }

      if (trackingId) {
        runningTrackingIds.push(trackingId);
      }
    });
  };
}
