// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Provider} from 'react-redux';
import {setStore} from '@modules/State/util/util';
import {store} from '../state/store';

interface _Props {
  children: any;
}

export const ReduxProvider: React.FC<_Props> = ({children}) => {
  setStore(store);

  return <Provider store={store}>{children}</Provider>;
};
