// @ts-nocheck
/* eslint-disable */

import {useContext} from 'react';
import {_TrackingContextProps, TrackingContext} from '@modules/Tracking/providers/TrackingProvider';

export const useTracking = (): _TrackingContextProps => {
  const context = useContext(TrackingContext);
  if (!context) {
    throw new Error('useTracking must be used within a TrackingProvider');
  }
  return context;
};
