// @ts-nocheck
/* eslint-disable */

import {registerNavigateToFlowCallback} from '@/_core/util/util';
import {useEffectAsync} from '@modules/Core/hooks/effectAsync';
import {logger} from '@modules/Core/util/Logger';
import {useNavigateToFlow} from '@modules/FlowEngine/hooks/navigateToFlow';
import {dispatchAction} from '@modules/State/util/util';
import {loadFlows} from '../services/api';
import {setFlows} from '../state/flowJsonSlice';

/**
 * This loads all flowJsons from the server on app init
 */
export function useOnInitFlow(): void {
  useOnInitFlowJson();
  useOnInitRegisterCallbacks();
}

export function useOnInitRegisterCallbacks(): void {
  const navigateToFlow = useNavigateToFlow();
  registerNavigateToFlowCallback(navigateToFlow);
}

export function useOnInitFlowJson(): void {
  useEffectAsync(async () => {
    logger.info('[useOnInitFlowJson] Loading flows...');
    const flowResponse = await loadFlows(false);
    dispatchAction(setFlows(flowResponse.data));
  }, []);
}
