// @ts-nocheck
/* eslint-disable */

import React, {ReactNode, Suspense} from 'react';
import {LoaderProvider} from '@modules/Core/hooks/state/loader';
import {AppNavigateProvider} from '@modules/Navigation/hooks/appNavigate';
import {ProfileGlobalDialogProvider} from '@modules/Profile/providers/ProfileGlobalDialogProvider';
import {ReduxProvider} from '@modules/State/providers/Provider';
import {TrackingProvider} from '@modules/Tracking/providers/TrackingProvider';
import {OnInit} from './OnInit';

interface _Props {
  children: ReactNode;
}

/**
 * Entry point of the application
 * @param children
 * @constructor
 */
export const Root: React.FC<_Props> = ({children}) => {
  return (
    <Suspense>
      {/* TODO @Stefan fallback={<h1>Loading (Suspense)...</h1>}> */}
      <ReduxProvider>
        <AppNavigateProvider>
          <LoaderProvider>
            <TrackingProvider>
              <ProfileGlobalDialogProvider>
                <OnInit>{children}</OnInit>
              </ProfileGlobalDialogProvider>
            </TrackingProvider>
          </LoaderProvider>
        </AppNavigateProvider>
      </ReduxProvider>
    </Suspense>
  );
};
