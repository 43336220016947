// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {usePathname} from 'next/navigation';
import {
  IconAdd,
  IconCreditCard,
  IconCycle,
  IconFactionary,
  IconFlow,
  IconHome,
  IconLogout,
  IconNote,
  IconOrganisation,
  IconProfile,
  IconProfilePrivacy,
  IconSettings,
  IconShare,
  IconSmile,
  IconSocialRole,
  IconSurvey,
  IconTaskCompleter,
  IconTeam,
  IconTeamAnalysis,
  IconTeamDevelopment,
} from '@modules/Core/components/base/Icons/Icons';

// const query = useSearchParams();

export const useGetIsActiveRoute = (pattern: string | string[] | null | undefined): boolean => {
  const pathname = usePathname();
  return getIsActiveRoute(pathname, pattern);
};

export const getIsActiveRoute = (pathname: string, pattern: string | string[] | null | undefined): boolean => {
  if (!pattern) return false;

  if (Array.isArray(pattern)) {
    // list of regex patterns
    // try to match each regex pattern
    return pattern.some(p => pathname.match(p));
  }

  return Boolean(pathname.match(pattern));
};

export const getRouteIcon = (routeValue: string): React.ElementType => {
  if (routeValue === 'dashboard') return IconHome;
  if (routeValue === 'softfacts') return IconSmile;
  if (routeValue === '360-feedback') return IconCycle;
  if (routeValue === 'shared-results') return IconShare;
  if (routeValue === 'profile-exercises') return IconTaskCompleter;
  if (routeValue === 'journal') return IconNote;
  if (routeValue.includes('team_')) return IconTeam;
  if (routeValue.includes('team.analysis_')) return IconTeamAnalysis;
  if (routeValue.includes('team.development_')) return IconTeamDevelopment;
  if (routeValue.includes('team.members_')) return IconTeam;
  if (routeValue === 'team.new') return IconAdd;
  if (routeValue === 'organisation.new') return IconAdd;
  if (routeValue === 'softfactionary') return IconFactionary;
  if (routeValue === 'login') return IconLogout;
  if (routeValue === 'logout') return IconLogout;
  // if (routeValue === 'register') return IconLogout;
  // if (routeValue === 'resetPassword') return IconLogout;
  if (routeValue === 'profile') return IconProfile;
  if (routeValue === 'membership') return IconCreditCard;
  if (routeValue === 'settings') return IconSettings;
  if (routeValue === 'account') return IconProfile;
  if (routeValue === 'privacy') return IconProfilePrivacy;
  if (routeValue === 'organisation') return IconFlow;
  if (routeValue === 'organisation.switch') return IconCycle;
  if (routeValue === 'notifications') return IconCycle;
  if (routeValue === 'Design system') return IconSocialRole;
  if (routeValue === 'surveys.custom') return IconSurvey;
  if (routeValue === 'organisation.dashboard') return IconHome;
  if (routeValue === 'organisation.structure') return IconOrganisation;
  if (routeValue === 'organisation.members') return IconTeam;
  return IconHome;
};
