// @ts-nocheck
/* eslint-disable */

import React, {ReactNode} from 'react';
import {useOnInitMain} from './hooks/onInit';

interface _Props {
  children: ReactNode;
}

/**
 * We need this to be inside Provider to be able to use the context
 * @param children
 * @constructor
 */
export const OnInit: React.FC<_Props> = ({children}) => {
  useOnInitMain();
  return children;
};
