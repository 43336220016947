// @ts-nocheck
/* eslint-disable */

import {useOnInitApp} from '@modules/Core/hooks/init/onInit';
import useLinkInterceptor from '@modules/Core/hooks/links';
import {useOnInitFlow} from '@modules/FlowEngine/hooks/onInit';
import {useOnInitInstitution} from '@modules/Institution/hooks/onInit';
import {useOnInitNavigation} from '@modules/Navigation/hooks/navigationInit';
import {useOnInitProfile} from '@modules/Profile/hooks/onInit';
import {useOnInitTeams} from '@modules/Team/hooks/onInit';
import {useOnInitTracking} from '@modules/Tracking/hooks/onInit';
import {useOnInitTranslations} from '@modules/Translations/hooks/onInit';

export function useOnInitMain(): void {
  // Global
  useLinkInterceptor();

  // Base
  useOnInitApp();
  useOnInitTranslations();
  useOnInitFlow();
  useOnInitNavigation();

  // Platform Modules/Sections
  useOnInitProfile();
  useOnInitInstitution();
  useOnInitTeams();

  // Tracking
  useOnInitTracking();
}
