// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {SideBarEntries} from '@/_core/sideBar/SideBarEntries';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {IconOrganisation, IconTeam} from '@modules/Core/components/base/Icons/Icons';
import {_NewSideBarRoute} from '@modules/Core/types/routesTypes';
import {useInstitution} from '@modules/Institution/hooks/Institution';
import {useProfile} from '@modules/Profile/hooks/profile';
import {trans} from '@modules/Translations/util/i18n';

interface _SideBarTeams {
  data: _NewSideBarRoute;
}

export const SideBarTeams: React.FC<_SideBarTeams> = ({data}) => {
  const [userProfile] = useProfile();
  const {currentInstitution} = useInstitution();

  if (!userProfile || !currentInstitution) {
    console.error('No user found');
    return null;
  }

  return (
    <div className="flex items-start gap-1 flex-col w-full mb-1">
      <div className="flex pl-2 w-full h-5 items-center justify-start">
        <AvatarUser label={trans('organisation.teams')} icon={IconTeam} lineClamp bold />
      </div>

      <SideBarEntries data={data} />
    </div>
  );
};
