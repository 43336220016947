// @ts-nocheck
/* eslint-disable */

import {createSlice} from '@reduxjs/toolkit';
import {DEFAULT_THEME} from '../config/constants';
import {logger} from '../util/Logger';

export interface _ThemeState {
  currentTheme: string;
}

const themeSlice = createSlice({
  name: 'theme',
  initialState: {currentTheme: DEFAULT_THEME},
  reducers: {
    setTheme(state: _ThemeState, action) {
      const themeName = action.payload ? action.payload : DEFAULT_THEME;

      logger.info('[ThemeSlice] Changing theme to ', `${themeName}.`);

      state.currentTheme = themeName;
    },
    resetTheme(state: _ThemeState, action) {
      logger.info('[ThemeSlice] Resetting theme to default theme ', `${DEFAULT_THEME}.`);

      state.currentTheme = DEFAULT_THEME;
    },
  },
});

export const {setTheme, resetTheme} = themeSlice.actions;
export default themeSlice;
