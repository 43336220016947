// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {SideBarEntries} from '@/_core/sideBar/SideBarEntries';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {_NewSideBarRoute} from '@modules/Core/types/routesTypes';
import {logger} from '@modules/Core/util/Logger';
import {_Profile} from '@modules/Profile/types/profile.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';

interface _SideBarUser {
  data: _NewSideBarRoute;
  userProfile: _Profile | null;
}

export const SideBarUser: React.FC<_SideBarUser> = ({data, userProfile}) => {
  if (!userProfile) {
    logger.error('No user found');
    return null;
  }

  const {fullName} = userProfile;

  return (
    <div className="flex items-start gap-1 flex-col w-full mb-1">
      {/* <div className="flex pl-2 w-full h-5 items-center justify-start" id="tutorialTestEinz"> */}
      {/*  <AvatarUser label={fullName} image={getProfilePic(userProfile)} /> */}
      {/* </div> */}

      <SideBarEntries data={data} />
    </div>
  );
};
