// @ts-nocheck
/* eslint-disable */

import React from 'react';
import './PageLayout.scss';
import {Action} from '@modules/Core/components/layout/Action';
import {usePageLayout} from '@modules/Core/hooks/state/pageLayout';

export const PageLayoutDevFooter: React.FC = () => {
  const {devActions} = usePageLayout();

  if (!devActions?.length) return null;
  return (
    <div className="fixed bottom-0 rounded-sm relative-col-width-12  bg-[#000000B2] bg-opacity-70 text-white p-2 z-[1000]">
      <div className="w-full flex flex-row gap-3 justify-between items-center">
        <div className="flex-grow">
          {devActions && devActions.length > 0 && (
            <div className="flex flex-row flex-wrap items-center justify-start h-full gap-3 w-full">
              {devActions.map(action => (
                <Action key={action.uuid} {...action} />
              ))}
            </div>
          )}
        </div>
        <img src="/img/brand/softfact.png" alt="logo" className="h-4" />
      </div>
    </div>
  );
};
