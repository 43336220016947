// @ts-nocheck
/* eslint-disable */

import {httpClient} from '../HttpClient';
import {LOAD_RESOURCES} from '../paths';

async function loadResources(): Promise<any> {
  return await httpClient.get(LOAD_RESOURCES);
}

export {loadResources};
