// @ts-nocheck
/* eslint-disable */

'use client';

import '../css/globals.css';
import '../css/fonts.css';
import '../css/globalGrid.css';
import '../css/index.css';
import '../css/table.css';
import '../css/tooltip.css';
import '../css/tutorialCard.css';
import React, {ReactElement, useEffect, useState} from 'react';
import * as Sentry from '@sentry/nextjs';
import {Karla, Montserrat} from 'next/font/google';
import {DevMenu} from '@/_core/DevMenu';
import {PageLayout} from '@/_core/PageLayout';
import {AppSidebar} from '@/_core/sideBar/AppSidebar';
import {TopBar, TopBarBackground} from '@/_core/topBar/TopBar';
import {addCN, GridHelper} from '@/_core/util/cssHelper';
import {GlobalAlert} from '@modules/Core/components/base/GlobalAlert';
import {GlobalDialog} from '@modules/Core/components/base/GlobalDialog';
import {Typography} from '@modules/Core/components/base/Typography';
import Loader from '@modules/Core/components/layout/Loader';
import MobileBanner from '@modules/Core/components/platform/banners/MobileBanner';
import {addReactEventListener, removeReactEventListener} from '@modules/Core/util/eventsUtil';
import {useUiStore} from '@modules/Core/util/logic/zustand';
import {useApplyBreakpoints} from '@modules/Core/util/util/useApplyBreakpoints';
import {Root} from '@modules/Main/Root';
import {DevOutput} from './_core/DevOutput';

const karla = Karla({
  subsets: ['latin'],
  variable: '--font-karla',
});

const mont = Montserrat({
  subsets: ['latin'],
  variable: '--font-mont',
});

// Set initial scale factor
// const scaleFactor = Math.max(1024, Math.min(window.innerWidth, 1920)) / 1920;
// document.documentElement.style.setProperty('--scale-factor', scaleFactor.toString());

// https://nextjs.org/docs/app/building-your-application/routing/pages-and-layouts#root-layout-required
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type,react/function-component-definition
export default function RootLayout({children}: {children: ReactElement}) {
  // todo@Sherif: Tip for performance optimization without using memo or sorts. This is a very high level component - the entire content goes through here
  // its best to remove all hooks and states (local or global) and put them in their respective components. e.g. showSideBar, showTopBar. They can cause rerenders but only for their component, not the entire tree.
  // useApplyBreakPoints is fine as its fully transient and does not cause rerenders

  const showTopBar = useUiStore(state => state.showTopBar);
  const [sidebarShowing, setSidebarShowing] = useState(false);
  const mainContentRef = React.useRef<HTMLDivElement>(null);

  useApplyBreakpoints();

  // TODO @Stefan change layout so this is not needed (see tmp/kreutix/nds6-responsive)
  // Scroll to top on navigation
  useEffect(() => {
    const scrollToTop = (): void => {
      if (mainContentRef.current) {
        mainContentRef.current.scrollTop = 0;
      }
    };
    addReactEventListener('navigate.start', scrollToTop);
    return () => removeReactEventListener('navigate.start', scrollToTop);
  }, []);

  const gtagJs =
    '!(function (e, t, a, n, g) {(e[n] = e[n] || []),e[n].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });var m = t.getElementsByTagName(a)[0],r = t.createElement(a);(r.async = !0),(r.src = "https://www.googletagmanager.com/gtm.js?id=GTM-N762LLP"),m.parentNode.insertBefore(r, m);})(window, document, "script", "dataLayer");';

  return (
    <html>
      <head>
        <title>soft.fact - Work Different</title>
        <link rel="preload" href="/assets/img/loader.webp" as="image" />

        <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no" />
        <meta name="title" content="soft.fact - Work different" />
        <meta
          name="description"
          content="The software that enables satisfied, mentally healthy and high performing teams – every day, measurable. Start now."
        />
        <meta name="author" content="soft.fact" />
        <title>soft.fact - Work Different</title>
        <meta property="og:title" content="soft.fact - Work different" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://my.softfacts.work" />
        <meta property="og:image" content="/assets/og/preview.png" />

        <script dangerouslySetInnerHTML={{__html: gtagJs}} />

        <link rel="apple-touch-icon" sizes="180x180" href="/assets/favicons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicons/favicon-16x16.png" />
        <link rel="manifest" href="/assets/favicons/site.webmanifest" />
        <link rel="mask-icon" href="/assets/favicons/safari-pinned-tab.svg" color="#ff5c5c" />
        <link rel="shortcut icon" href="/assets/favicons/favicon.ico" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-config" content="/assets/favicons/browserconfig.xml" />
      </head>

      <body>
        <main className={addCN(karla.variable, mont.variable)}>
          {/* the TopBar is NOT part of the main grid by design. Paddings / max widths have to be implemented twice - for the TopBar and the mainGrid */}
          <Sentry.ErrorBoundary
            fallback={
              <div className="mainContent overflow-x-hidden overflow-y-scroll h-[100vh] flex flex-col">
                <div className="flex flex-col w-full h-full justify-center items-center">
                  <Typography>
                    Unexpected error. Take me <a href="/">home.</a>
                  </Typography>
                </div>
              </div>
            }
            showDialog={false}
          >
            <Root>
              <DevOutput />
              <DevMenu />
              <Loader mainLoader>
                <div
                  ref={mainContentRef}
                  className="mainContent overflow-x-hidden overflow-y-scroll h-[100vh] flex flex-col"
                >
                  {/* Left side */}
                  {showTopBar ? <TopBarBackground /> : null}
                  <AppSidebar showSideBar={sidebarShowing} setShowSideBar={setSidebarShowing} />

                  {/* Right side: content */}
                  <div className={addCN('globalGridContainer flex-grow', sidebarShowing ? 'hasSideBar' : '')}>
                    <div className={addCN('h-full m-auto globalGrid', sidebarShowing ? 'hasSideBar' : '')}>
                      <GridHelper />
                      {showTopBar ? <TopBar showLogo={!sidebarShowing} /> : null}
                      <GlobalAlert mainAlert />
                      <GlobalDialog />
                      <MobileBanner />

                      <PageLayout setShowSidebar={setSidebarShowing}>{children}</PageLayout>
                    </div>
                  </div>
                </div>
              </Loader>
            </Root>
          </Sentry.ErrorBoundary>
        </main>
      </body>
    </html>
  );
}
