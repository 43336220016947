// @ts-nocheck
/* eslint-disable */

import React, {useMemo} from 'react';
import {usePathname} from 'next/navigation';
import {loggingRoutes, userMenuRoutes} from '@/_core/routes';
import {useClickOutOfBoundsHelper} from '@/_core/util/cssHelper';
import {getRouteIcon} from '@/_core/util/routesHelper';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {ListItem} from '@modules/Core/components/base/inputs/ListItem';
import {useUiStore, zustandApi} from '@modules/Core/util/logic/zustand';
import {useAppNavigate} from '@modules/Navigation/hooks/appNavigate';
import {useProfile} from '@modules/Profile/hooks/profile';
import {_Profile} from '@modules/Profile/types/profile.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _TopBarUser {
  profile: _Profile | null;
}

// TODO @Sherif clean this up per favor
// required for the click outside helper
const buttonId = 'topBarUserButton';

export const TopBarUser: React.FC<_TopBarUser> = ({profile}) => {
  const showUserMenu = useUiStore(state => state.showUserMenu);

  if (!profile) return null;

  return (
    <div className="flexCenterCenter">
      <button
        type="button"
        id={buttonId}
        onClick={(): void => zustandApi.handleShowExclusiveUi({showUserMenu: !showUserMenu})}
      >
        <AvatarUser label={profile.fullName} image={getProfilePic(profile)} />
      </button>

      {showUserMenu ? <UserMenu /> : null}
    </div>
  );
};

// todo: click outside to close
const UserMenu: React.FC = () => {
  const {navigate} = useAppNavigate();
  const pathname = usePathname();
  const [currentProfile] = useProfile();

  const userItems = useMemo(
    () => [
      userMenuRoutes.profile,
      userMenuRoutes.membership,
      ...(currentProfile?.institutions?.length > 1 ? [userMenuRoutes.organisation_switch] : []),
      userMenuRoutes.settings,
      loggingRoutes.logout,
    ],
    []
  );

  const getIsActive = (value: string, to: string): boolean => {
    return pathname?.includes(to);
  };

  const handleClick = (to: string): void => {
    navigate(to);
    zustandApi.updateUiStore({showUserMenu: false});
  };

  const handleClickOutside = (): void => zustandApi.updateUiStore({showUserMenu: false});
  const ref = useClickOutOfBoundsHelper(handleClickOutside, buttonId);

  return (
    <div ref={ref} className="absolute z-40 shadow-2 right-[0px] top-[100%] bg-white500 mt-1 relative-col-width-2">
      {Object.values(userItems).map((item, i) => (
        <ListItem
          key={item.value}
          active={getIsActive(item.value, item.to)}
          label={trans(item.displayValue)}
          onChange={(): void => {
            if (item.to) {
              handleClick(item.to);
            } else if (item.onClick) {
              item.onClick();
            }
          }}
          icon={getRouteIcon(item.value)}
          lineClamp
        />
      ))}
    </div>
  );
};
