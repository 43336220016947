// @ts-nocheck
/* eslint-disable */

import {useEffect} from 'react';
import {useAppNavigate} from '@modules/Navigation/hooks/appNavigate';

const useLinkInterceptor = (): void => {
  const {navigate} = useAppNavigate();

  useEffect(() => {
    const handleClick = (event: MouseEvent): void => {
      const target = event.target as HTMLElement;
      const anchor = target.closest('a');
      const href = anchor?.getAttribute('href');
      const anchorTarget = anchor?.getAttribute('target');
      if (href && !href.startsWith('http')) {
        event.preventDefault();
        navigate(href, {}, anchorTarget);
      }
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [navigate]);
};

export default useLinkInterceptor;
