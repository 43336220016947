// @ts-nocheck
/* eslint-disable */

import {JSX} from 'react';

export const addToClasses = (id: string, className: string): void =>
  document?.getElementById(id)?.classList?.add(className);
export const removeFromClasses = (id: string, className: string): void =>
  document?.getElementById(id)?.classList?.remove(className);

export const BreakpointHelper = (): JSX.Element => (
  <div
    style={{
      position: 'absolute',
      zIndex: 10000,
      top: 20,
      left: '50%',
      transform: 'translate(-50%, 0)',
      display: 'flex',
      flexDirection: 'row',
      gap: 6,
      background: 'black',
    }}
  >
    <span id="window_breakpoint" className="text-white">
      0
    </span>
    <span id="window_width" className="text-white">
      0
    </span>
    <span id="window_height" className="text-white">
      0
    </span>
  </div>
);
