// @ts-nocheck
/* eslint-disable */

import {useSelectState} from '@modules/State/hooks/selectState';
import {_AppState} from '@modules/State/types/appState.model';
import {_FlowSchema} from '../types/core.model';

/**
 * This hook returns the flowSchema object from redux by name.
 */
export function useFlowJsons(): Record<string, _FlowSchema> {
  return useSelectState((state: _AppState) => state.flowJson?.flows);
}
