// @ts-nocheck
/* eslint-disable */

import {useEffect, useState} from 'react';
import {usePathname} from 'next/navigation';
import {SideBarRoutes} from '@/_core/routes';
import {getIsActiveRoute} from '@/_core/util/routesHelper';
import {flowRoute} from '@/_core/util/util';
import {useFeatureFlags} from '@modules/Core/hooks/featureFlags';
import {
  _NewSideBarRouteItems,
  _NewSideBarRoutes,
  _Route,
  _NestedRoute,
  SideBarItem,
  DisplayValueParams,
} from '@modules/Core/types/routesTypes';
import {logger} from '@modules/Core/util/Logger';
import {copyObject} from '@modules/Core/util/util';
import {_FlowSchema} from '@modules/FlowEngine/types/core.model';
import {_Institution} from '@modules/Institution/types/institution.model';
import {_Profile} from '@modules/Profile/types/profile.model';
import {_Team} from '@modules/Team/types/team.model';

/**
 * Helper function to resolve displayValue whether it's static or dynamic
 * @param displayValue - The display value (string or function)
 * @param params - Parameters to pass to dynamic display value function
 * @returns Resolved string display value
 */
const resolveDisplayValue = (
  displayValue: string | ((params: DisplayValueParams) => string),
  params: DisplayValueParams = {}
): string => {
  return typeof displayValue === 'function' ? displayValue(params) : displayValue;
};

/**
 * Helper function to resolve shouldDisplay whether it's static or dynamic
 * @param shouldDisplay - The display condition (boolean or function)
 * @param params - Parameters to pass to dynamic shouldDisplay function
 * @returns Resolved boolean value
 */
const resolveShouldDisplay = (
  shouldDisplay?: boolean | ((params: DisplayValueParams) => boolean),
  params: DisplayValueParams = {}
): boolean => {
  if (shouldDisplay === undefined) return true;
  return typeof shouldDisplay === 'function' ? shouldDisplay(params) : shouldDisplay;
};

/**
 * Recursively filters out items where shouldDisplay is false throughout the navigation tree
 * Works for both individual sections and the entire navigation structure
 * @param items - Navigation items or structure to filter
 * @returns Filtered navigation items or structure
 */
const filterItems = (items: _NewSideBarRouteItems | _NewSideBarRoutes): _NewSideBarRouteItems | _NewSideBarRoutes => {
  // Handle section-level filtering for _NewSideBarRoutes
  if (typeof items === 'object' && !Array.isArray(items) && items !== null) {
    return Object.entries(items).reduce<_NewSideBarRoutes>(
      (acc, [key, value]) => ({
        ...acc,
        [key]: {
          items: filterItems(value.items) as _NewSideBarRouteItems,
        },
      }),
      {}
    );
  }

  // Handle array of items (recursive case)
  if (Array.isArray(items)) {
    return items
      .filter(item => {
        const sideBarItem = item as SideBarItem;
        return sideBarItem.shouldDisplay !== false;
      })
      .map(item => {
        const sideBarItem = item as SideBarItem;
        if (sideBarItem.items?.length) {
          return {
            ...sideBarItem,
            items: filterItems(sideBarItem.items) as _NewSideBarRouteItems,
          };
        }
        return sideBarItem;
      }) as _NewSideBarRouteItems;
  }

  return [];
};

/**
 * Interface defining parameters required to prepare sidebar items
 * @property pathname - Current URL path for determining active routes
 * @property currentProfile - Currently logged in user's profile data
 * @property currentInstitution - Current organization/institution context
 * @property availableRoutes - Map of flow keys to their schema definitions
 * @property items - Array of sidebar route items to be processed
 * @property teamId - Optional team context identifier
 * @property params - Additional parameters for route preparation
 * @property isFeatureEnabled - Function to check if a feature flag is enabled
 */
interface PrepareItemsParams {
  pathname: string;
  currentProfile?: _Profile | null;
  currentInstitution?: _Institution | null;
  availableRoutes: Record<string, _FlowSchema>;
  items: _NewSideBarRouteItems;
  teamId?: string;
  params?: Record<string, any>;
  isFeatureEnabled?: (featureFlag: string) => boolean;
}

/**
 * Hook that prepares and manages the sidebar navigation structure.
 * Handles four main navigation sections: profile, team, organization, and general.
 * Processes each section's items based on:
 * - Available flow schemas
 * - User permissions and roles
 * - Feature flags
 * - Team memberships
 * - Organization context
 *
 * Updates the sidebar structure whenever relevant dependencies change.
 *
 * @param availableRoutes - Map of flow keys to their schema definitions
 * @param currentProfile - Currently logged in user's profile
 * @param currentInstitution - Current organization context
 * @param teams - Array of teams the user belongs to
 * @returns Structured sidebar navigation with processed items for each section
 */
export const usePrepareSideBar = (
  availableRoutes: Record<string, _FlowSchema> = {},
  currentProfile: _Profile | null,
  currentInstitution: _Institution | null | undefined,
  teams: _Team[] = []
): _NewSideBarRoutes => {
  const [items, setItems] = useState<_NewSideBarRoutes>(() => ({
    profile: {items: []},
    team: {items: []},
    organisation: {items: []},
    general: {items: []},
  }));

  const pathname = usePathname();
  const {isFeatureEnabled} = useFeatureFlags();

  useEffect(() => {
    const prepareParams: Omit<PrepareItemsParams, 'items'> = {
      pathname,
      currentProfile,
      currentInstitution,
      availableRoutes,
      teamId: undefined,
      params: {},
      isFeatureEnabled,
    };

    const newItems: _NewSideBarRoutes = {
      profile: {
        items: prepareItems({
          ...prepareParams,
          items: SideBarRoutes.profile.items,
        }),
      },
      team: {
        items: prepareTeamSideBarItems({
          ...prepareParams,
          items: SideBarRoutes.team.items,
          teams,
        }),
      },
      organisation: {
        items: prepareInstitutionItems({
          ...prepareParams,
          items: SideBarRoutes.organisation.items,
          params: {institutionId: currentInstitution?.id},
          institution: currentInstitution,
        }),
      },
      general: {
        items: prepareItems({
          ...prepareParams,
          items: SideBarRoutes.general.items,
        }),
      },
    };

    // Filter out items that should not be displayed using the combined function
    const filteredItems = filterItems(newItems) as _NewSideBarRoutes;

    setItems(filteredItems);
  }, [availableRoutes, currentProfile, currentInstitution, teams, pathname]);

  return items;
};

/**
 * Prepares institution-specific sidebar items with dynamic display values.
 * Processes items that need institution context for:
 * - Display text generation
 * - Visibility conditions
 * - Route parameters
 *
 * Handles both static and dynamic display values, replacing function-based
 * display values with their computed results using the institution context.
 *
 * @param items - Base sidebar items to process
 * @param institution - Current institution context
 * @param params - Additional preparation parameters
 * @returns Array of processed institution sidebar items
 */
const prepareInstitutionItems = ({
  items,
  institution,
  ...params
}: PrepareItemsParams & {institution: _Institution | null | undefined}): _NewSideBarRouteItems => {
  const itemsWithDisplayValue = items.map(item => ({
    ...item,
    displayValue: resolveDisplayValue((item as SideBarItem).displayValue, {institution}),
    shouldDisplay: resolveShouldDisplay((item as SideBarItem).shouldDisplay, {institution}),
  }));

  return prepareItems({...params, items: itemsWithDisplayValue});
};

/**
 * Prepares team-related sidebar items, handling both team-specific and general team items.
 * For each team the user belongs to:
 * - Creates team-specific navigation items
 * - Processes display values with team context
 * - Generates proper routes with team IDs
 * - Handles nested items within team sections
 *
 * Also processes non-team items that exist in the team section.
 * Maintains proper hierarchy and structure of team navigation.
 *
 * @param pathname - Current URL path
 * @param currentProfile - User profile for permission checks
 * @param currentInstitution - Institution context
 * @param availableRoutes - Available flow schemas
 * @param items - Base sidebar items
 * @param teams - User's teams
 * @param isFeatureEnabled - Feature flag checker
 * @returns Processed team navigation structure
 */
const prepareTeamSideBarItems = ({
  pathname,
  currentProfile,
  currentInstitution,
  availableRoutes,
  items = [],
  teams = [],
  isFeatureEnabled,
}: PrepareItemsParams & {teams: _Team[]}): _NewSideBarRouteItems => {
  if (!items?.length) return [];

  const teamItems = items.filter(item => (item as SideBarItem).type === 'team');
  const nonTeamItems = items.filter(item => (item as SideBarItem).type !== 'team');

  const preparedTeamItems = teams.flatMap(team =>
    teamItems.map(teamItem => {
      const baseItem = teamItem as SideBarItem;
      const teamRouteItem = copyObject(baseItem.default);
      const itemsWithDisplayValue = (baseItem.items || []).map((item: SideBarItem) => ({
        ...item,
        displayValue: resolveDisplayValue(item.displayValue, {institution: currentInstitution, team}),
        shouldDisplay: resolveShouldDisplay(item.shouldDisplay, {institution: currentInstitution, team}),
      }));

      return {
        ...teamRouteItem,
        displayValue: resolveDisplayValue(baseItem.displayValue, {institution: currentInstitution, team}),
        shouldDisplay: resolveShouldDisplay(baseItem.shouldDisplay, {institution: currentInstitution, team}),
        collapsable: true,
        to: flowRoute(baseItem.default?.flowKey, team.id),
        pattern: flowRoute(baseItem.default?.flowKey, team.id),
        value: `team_${team.id}`,
        items: prepareItems({
          pathname,
          currentProfile,
          currentInstitution,
          availableRoutes,
          items: itemsWithDisplayValue,
          teamId: team.id,
          isFeatureEnabled,
        }),
      };
    })
  );

  const preparedNonTeamItems = nonTeamItems.map(item => {
    const baseItem = item as SideBarItem;
    const itemsWithDisplayValue = (baseItem.items || []).map((item: SideBarItem) => ({
      ...item,
      displayValue: resolveDisplayValue(item.displayValue, {institution: currentInstitution}),
      shouldDisplay: resolveShouldDisplay(item.shouldDisplay, {institution: currentInstitution}),
    }));

    return {
      ...baseItem,
      displayValue: resolveDisplayValue(baseItem.displayValue, {institution: currentInstitution}),
      shouldDisplay: resolveShouldDisplay(baseItem.shouldDisplay, {institution: currentInstitution}),
      items: itemsWithDisplayValue,
    };
  });

  return [...preparedTeamItems, ...preparedNonTeamItems];
};

/**
 * Core function to prepare and validate sidebar items.
 * Performs multiple processing steps:
 * 1. Filters items based on available flows
 * 2. Checks display conditions against user context
 * 3. Validates feature flag requirements
 * 4. Processes items based on their type (collapsible vs regular)
 * 5. Handles nested items recursively
 *
 * Ensures all items are properly formatted and have required properties
 * before being displayed in the sidebar.
 *
 * @param params - Complete set of preparation parameters
 * @returns Processed and validated sidebar items
 */
const prepareItems = ({
  pathname,
  currentProfile,
  currentInstitution,
  availableRoutes,
  items,
  teamId,
  params = {},
  isFeatureEnabled,
}: PrepareItemsParams): _NewSideBarRouteItems => {
  if (!items) return [];

  return items
    .filter(item => !item.flowKey || availableRoutes?.[item.flowKey])
    .filter(item => {
      const sideBarItem = item as SideBarItem;
      return !sideBarItem.featureFlag || isFeatureEnabled?.(sideBarItem.featureFlag);
    })
    .map(itemOriginal => {
      const item = copyObject(itemOriginal) as SideBarItem;
      if (!item) return null;

      item.shouldDisplay = resolveShouldDisplay((itemOriginal as SideBarItem).shouldDisplay, {
        profile: currentProfile,
        institution: currentInstitution,
        teamId,
      });

      if (item.collapsable) {
        return prepareCollapsableItem({
          item,
          itemOriginal: itemOriginal as SideBarItem,
          pathname,
          currentProfile,
          currentInstitution,
          availableRoutes,
          teamId,
          params,
        });
      }

      return prepareRegularItem({item, teamId, pathname});
    })
    .filter(Boolean) as _NewSideBarRouteItems;
};

/**
 * Prepares a collapsible sidebar item with nested items and dynamic values.
 * Handles:
 * - Nested item preparation
 * - Dynamic display value computation
 * - Route pattern generation
 * - Active state determination
 * - Parameter injection into routes
 *
 * Used for items that can expand/collapse to show/hide nested navigation options.
 *
 * @param item - Current sidebar item being processed
 * @param itemOriginal - Original unmodified item for reference
 * @param pathname - Current URL path
 * @param params - Additional preparation parameters
 * @returns Fully processed collapsible sidebar item
 */
const prepareCollapsableItem = ({
  item,
  itemOriginal,
  pathname,
  currentProfile,
  currentInstitution,
  availableRoutes,
  teamId,
  params,
}: {
  item: SideBarItem;
  itemOriginal: SideBarItem;
  pathname: string;
  currentProfile?: _Profile | null;
  currentInstitution?: _Institution | null;
  availableRoutes: Record<string, _FlowSchema>;
  teamId?: string;
  params: Record<string, any>;
}): SideBarItem => {
  item.items = prepareItems({
    pathname,
    currentProfile,
    currentInstitution,
    availableRoutes,
    items: itemOriginal.items || [],
    teamId,
    params,
  });

  item.displayValue = resolveDisplayValue(itemOriginal.displayValue, {
    teamId,
    currentProfile,
    currentInstitution,
  });

  item.shouldDisplay = resolveShouldDisplay(itemOriginal.shouldDisplay, {
    teamId,
    profile: currentProfile,
    institution: currentInstitution,
  });

  if (item.default?.flowKey) {
    item.to = flowRoute(item.default.flowKey, teamId, params);
    item.pattern = item.pattern || flowRoute(item.default.flowKey, teamId);
  }
  item.active = getIsActiveRoute(pathname, item.pattern);

  return item;
};

/**
 * Prepares a regular (non-collapsible) sidebar item.
 * Processes:
 * - Route generation with proper parameters
 * - Pattern matching for active state
 * - Value generation with team context
 * - Active state determination
 *
 * Used for simple navigation items that directly link to a route
 * without nested options.
 *
 * @param item - Sidebar item to prepare
 * @param teamId - Optional team context for route generation
 * @param pathname - Current path for active state checking
 * @returns Processed regular sidebar item
 */
const prepareRegularItem = ({
  item,
  teamId,
  pathname,
}: {
  item: SideBarItem;
  teamId?: string;
  pathname: string;
}): SideBarItem => {
  if (item.flowKey) {
    item.to = flowRoute(item.flowKey, teamId);
    item.pattern = item.pattern || flowRoute(item.flowKey, teamId);
  }

  if (teamId) {
    item.value = `${item.value}_${teamId}`;
    item.shouldDisplay = resolveShouldDisplay(item.shouldDisplay, {});
  }

  item.active = getIsActiveRoute(pathname, item.pattern);
  return item;
};
